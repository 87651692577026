import Vue from 'vue'
import Component from 'vue-class-component'
import PaymentOption from '~/components/order/payment'
import StatusOption, {mStatusOptions} from '~/components/order/status'
import NoteLanguage from '~/components/course/note-language'
import {MailingStatus, Order, OrderStatus, PaymentMethod} from '~/components/data-class/data-class'

@Component
export default class OrderMixin extends Vue {
    OrderStatus = OrderStatus
    MailingStatus = MailingStatus

    paymentFilters = PaymentOption
    statusFilters = StatusOption
    mStatusOptions = mStatusOptions
    noteLanguage = NoteLanguage

    statusElType = {
        [OrderStatus.MAILED]: 'success',
        [OrderStatus.PAID]: 'success',
        [OrderStatus.CANCELED]: 'danger',
        [OrderStatus.REFUNDED]: 'danger',
    }

    mStatusElType = {
        [MailingStatus.MAILED]: 'success',
        [MailingStatus.TB_MAILED]: 'warning',
        [MailingStatus.PENDING]: '',
        [MailingStatus.NO_NEED]: 'info',
    }

    getStatusText(status: OrderStatus) {
        const s = this.statusFilters.find(s => s.value === status)
        return s ? s.text : ''
    }

    getMStatusText(status: MailingStatus) {
        const s = this.mStatusOptions.find(s => s.value === status)
        return s ? s.text : ''
    }

    getPaymentText(p: PaymentMethod) {
        const found = this.paymentFilters.find(item => {
            return item.value === p
        })
        return found ? found.text : ''
    }

    statusDisabled(order: Order, status: OrderStatus) {
        switch (status) {
            case OrderStatus.PENDING:
                return false
            case OrderStatus.PAID:
            case OrderStatus.CANCELED:
                return ![OrderStatus.PENDING, OrderStatus.TO_BE_VERIFIED, OrderStatus.VERIFICATION_FAILED]
                    .includes(order.status)
            case OrderStatus.TO_BE_VERIFIED:
                return order.status !== OrderStatus.PENDING || !order.verification_image
            default:
                return true
        }
    }

    mStatusDisabled(order: Order, status: MailingStatus) {
        if(order.mailing_status === status)
            return true
        switch (status) {
            case MailingStatus.MAILED:
                return order.mailing_status !== MailingStatus.TB_MAILED
            case MailingStatus.TB_MAILED:
                return !(order.status > 0)
            case MailingStatus.PENDING:
                return (order.status > 0)
            case MailingStatus.NO_NEED:
                return false
            default:
                return true
        }
    }
}
