const noteLanguageOptions = [
    {
        text: '-',
        text_e: '',
        value: -1
    },
    {
        text: '中文',
        text_e: 'c',
        value: 0
    },
    {
        text: '英文',
        text_e: 'e',
        value: 1

    },
    {
        text: '中英對照',
        text_e: '',
        value: 3
    }
]

export default noteLanguageOptions
