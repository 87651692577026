


























































































































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import copy from 'copy-to-clipboard'
import {MailingStatus, Order, OrderStatus, PurchaseRecord} from '~/components/data-class/data-class'
import NoteLanguage from '~/components/course/note-language'
import {randomColor} from '~/utils/random-color'
import {sfLookup, updateLocations} from '~/components/sf-express/sf-location-storage'
import {OrderPermission} from '~/utils/permissions'
import WaybillScannerDialog from '~/components/waybill-scanner-dialog.vue'
import {Dict} from '~/utils/misc'
import {mixins} from 'vue-class-component'
import OrderMixin from '~/mixins/order-mixin'
import ResendDialog from '~/components/order/resend-dialog.vue'

enum Type {
    Order = 0,
    Purchase = 1
}

interface Row {
    _id: string | { member_id: string, id: number },
    key: string,
    member_id: string,
    waybill_number: string
    type: Type,
    order?: Order,
    status?: OrderStatus,
    mailing_status?: MailingStatus,
    sent?: boolean,
    items: string[],
    short?: string
}

function shortCourseId(courseId: string) {
    const parts = courseId.match(/[a-zA-Z]+|[0-9]+/g) || ['', '0']
    return parts[0] + Number(parts[1]) + (parts[2] || '')
}

@Component({
    components: {
        WaybillScannerDialog,
        ResendDialog
    },
    metaInfo() {
        return {
            title: 'Mobile Order List'
        }
    }
})
export default class MobileOrdersList extends mixins(OrderMixin) {
    static permission = OrderPermission.Order

    Type = Type
    searchKeywords = ''
    sortMember = true
    noteLanguage = NoteLanguage

    filteredCourse: string[] = []
    filterOptions: string[] = []

    rawTableData: Row[] = []
    tableData: Row[] = []
    displayName: Dict<string> = {}

    addWaybillOrderTemp: Row


    showSummary = false

    dialogAddWaybillNumber = false

    summary: { label: string, count: number }[] = []
    SORT_BY_COURSE = 0
    SORT_BY_COUNT = 1
    sort = this.SORT_BY_COURSE
    order = 1
    resendOrder: Order = new Order()
    dialogResendVisible: boolean = false

    get totalC() {
        return this.summary.reduce((t, s) => t + s.count, 0)
    }

    randomColor(str: string) {
        return randomColor(str)
    }

    toggleSort() {
        this.sortMember = !this.sortMember
        this.getOrder()
    }

    search(keyword?: string) {
        if (keyword) {
            this.searchKeywords = (keyword === this.searchKeywords) ? '' : keyword
        }
        this.getOrder()
    }

    async created() {
        this.getOrder()
        await updateLocations()
    }

    openAddWaybillNumberToOrder(row: Row) {
        this.addWaybillOrderTemp = row
        this.dialogAddWaybillNumber = true
    }

    async codeScanned(awbNo: string) {
        await createRequest('/courses/order/' + this.addWaybillOrderTemp._id + '/mailing-status', 'patch', {},
            {
                mailing_status: MailingStatus.MAILED,
                waybill_number: awbNo
            }).send()
        this.addWaybillOrderTemp.mailing_status = MailingStatus.MAILED
        this.addWaybillOrderTemp.waybill_number = awbNo
        this.addWaybillOrderTemp.order!!.mailing_status = MailingStatus.MAILED
        this.addWaybillOrderTemp.order!!.waybill_number = awbNo
    }

    async getOrder() {
        const res = await createRequest('/courses/orders/to-be-mailed', 'get').send()
        const docs = res.data.docs

        const dict = {}
        this.rawTableData = docs.map((doc) => {
            if (doc['member_id']) {
                const o = doc as Order
                o.courses = o.courses.filter(oc => oc.note)

                const courses: { course_id: string, language: number }[] = (o.resend && o.resend.length) ? o.resend : o.courses

                const items = courses.map((c) => {
                    let lang = ''
                    for (const l of this.noteLanguage) {
                        if (l.value === c.language && [0, 1].includes(l.value))
                            lang = `-(${l.text_e})`
                    }
                    const title = c.course_id + lang

                    dict[title] = (dict[title] || 0) + 1
                    return title
                })

                this.displayName[o.member_id] = o.display_name
                return {
                    _id: o._id,
                    key: o._id,
                    member_id: o.member_id,
                    waybill_number: o.waybill_number,
                    order: o,
                    type: Type.Order,
                    status: o.status,
                    mailing_status: o.mailing_status,
                    items: items
                }
            } else {
                const p = doc as PurchaseRecord
                return {
                    _id: p._id,
                    key: p._id.member_id + '-' + p._id.id,
                    member_id: p._id.member_id,
                    waybill_number: p.waybill_number,
                    type: Type.Purchase,
                    sent: Boolean(p.waybill_number),
                    items: [p.title + ' x' + p.count],
                    short: p.short! + p.count
                }
            }
        })

        this.tableData = this.rawTableData.slice()

        this.filterOptions = Object.keys(dict).sort()
        this.filteredCourse = this.filterOptions.slice()

        this.updateSummary()
        this.sortSummary()
    }

    updateSummary() {
        const dict = {}

        for (const row of this.tableData) {
            if (row.type !== Type.Order)
                continue

            const o = row.order!
            const courses: { course_id: string, language: number }[] = (o.resend && o.resend.length) ? o.resend : o.courses

            for (const c of courses) {
                let lang = ''
                for (const l of this.noteLanguage) {
                    if (l.value === c.language && [0, 1].includes(l.value))
                        lang = `-(${l.text_e})`
                }
                const title = c.course_id + lang

                dict[title] = (dict[title] || 0) + 1
            }
        }

        this.summary = Object.keys(dict).map(k => {
            return {
                label: k,
                count: dict[k]
            }
        })
    }

    courseIdSortClicked() {
        if (this.sort === this.SORT_BY_COUNT) {
            this.sort = this.SORT_BY_COURSE
            this.order = 1
        } else {
            this.order = -1 * this.order
        }
        this.sortSummary()
    }

    countSortClicked() {
        if (this.sort === this.SORT_BY_COURSE) {
            this.sort = this.SORT_BY_COUNT
            this.order = 1
        } else {
            this.order = -1 * this.order
        }
        this.sortSummary()
    }

    sortSummary() {
        this.summary.sort((a, b) => {
            const a1 = this.sort === this.SORT_BY_COUNT ? a.count : a.label
            const a2 = this.sort === this.SORT_BY_COUNT ? a.label : a.count
            const b1 = this.sort === this.SORT_BY_COUNT ? b.count : b.label
            const b2 = this.sort === this.SORT_BY_COUNT ? b.label : b.count

            let r
            if (a1 === b1)
                r = (a2 > b2) ? 1 : -1
            else
                r = (a1 > b1) ? 1 : -1

            return this.order === 1 ? r : r * -1
        })
    }

    changePayment(row, payment) {
        createRequest('/courses/order/' + row._id + '/status', 'patch', {}, {
            status: 1,
            payment_method: payment
        }).send()
            .then(() => {
                row.status = 1
                row.payment_method = payment
            })
    }

    // drop down item select
    async changeMStatus(row: Row, status: MailingStatus) {
        if (status === MailingStatus.TB_MAILED && [MailingStatus.MAILED, MailingStatus.NO_NEED].includes(row.mailing_status!)) {
            this.startResendOrder(row.order!)
        } else if (status === MailingStatus.MAILED) {
            this.openAddWaybillNumberToOrder(row)
        } else {
            await createRequest('/courses/order/' + row._id + '/mailing-status', 'patch', {},
                {mailing_status: status}).send()
            this.$set(row, 'mailing_status', status)
            this.$set(row.order!, 'mailing_status', status)
        }
    }

    startResendOrder(order: Order) {
        this.resendOrder = order
        this.dialogResendVisible = true
    }

    purchaseSend(row: Row) {
        const id = row._id as { member_id: string, id: number }
        const orderRow = this.tableData.find((r) => {
            return r.member_id === id.member_id && r.status === OrderStatus.MAILED
        })

        if (!orderRow) {
            this.$message({
                message: 'No mailed order',
                type: 'error'
            })
            return
        }

        createRequest('/shop/purchase-record/send', 'post', {}, {
            _id: row._id,
            order_id: orderRow._id
        }).send()
            .then(() => {
                row.waybill_number = orderRow.waybill_number
                row.sent = true
            })
            .catch((e) => {
                this.$message({
                    message: 'Error ' + JSON.stringify(e),
                    type: 'error'
                })
            })
    }

    batchSFx() {
        const data: Dict<{
            displayName: string,
            items: string[],
            phone: string,
            shippingCode: string,
            orderIds: string[],
            coupon: boolean
        }> = {}
        for (const row of this.tableData) {
            if (!data[row.member_id]) {
                data[row.member_id] = {
                    displayName: this.displayName[row.member_id],
                    items: [],
                    phone: '',
                    shippingCode: '',
                    orderIds: [],
                    coupon: false
                }
            }

            const d = data[row.member_id]

            if (row.order) {
                d.items.push(...row.items)
                d.orderIds.push(row.order._id)
                d.phone = row.order.phone
                d.shippingCode = row.order.shipping_code
            } else {
                d.coupon = true
            }
        }

        function addCp(d) {
            return d.coupon ? ',CP' : ''
        }

        const rows: string[] = []
        for (const mid in data) {
            const d = data[mid]

            let items = d.items.join(',') + addCp(d)
            if (items.length > 20) {
                items = d.items.map((i) => shortCourseId(i)).join(',') + addCp(d)

                if (items.length > 20) {
                    items = d.orderIds.join(',') + addCp(d)

                    if (items.length > 20) {
                        items = mid.replace(/MSID-0*/, '') + addCp(d)
                    }
                }
            }

            rows.push([d.orderIds[0], d.phone, '', items, '', '', '', '', '寄付月结', '8526898032', '', d.shippingCode].join('\t'))
        }

        copy(rows.join('\n'), {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message('Coped!!')
    }

    batchSF() {
        const data: {
            memberId: string,
            displayName: string,
            items: string[],
            phone: string,
            shippingCode: string,
            orderId: string,
            coupon: string[]
        }[] = this.tableData.filter(r => !!r.order)
            .map(row => {
                const order = row.order!
                return {
                    memberId: row.member_id,
                    displayName: this.displayName[row.member_id],
                    items: row.items,
                    phone: order.phone,
                    shippingCode: order.shipping_code.trim(),
                    orderId: order._id,
                    coupon: []
                }
            })

        for (const row of this.tableData.filter(r => !r.order)) {
            const found = data.find(d => d.memberId === row.member_id)
            if (found)
                found.coupon.push(row.short!)
        }

        function addCp(d) {
            return d.coupon.length? ',' + d.coupon.join(',') : ''
        }

        function addCpShort(d) {
            return d.coupon.length? ',CP' : ''
        }

        const rows: string[] = []
        for (const mid in data) {
            const d = data[mid]

            let items = d.items.join(',') + addCp(d)
            if (items.length > 20) {
                items = d.items.map((i) => shortCourseId(i)).join(',') + addCp(d)

                if (items.length > 20) {
                    items = d.orderId + addCp(d)

                    if (items.length > 20) {
                        items = d.orderId + addCpShort(d)
                        if (items.length > 20) {
                            items = mid.replace(/MSID-0*/, '') + addCpShort(d)
                        }
                    }
                }
            }

            rows.push([d.orderId, d.phone, '', items, '', '', '', '', '寄付月结', '8526898032', '', d.shippingCode].join('\t'))
        }

        copy(rows.join('\n'), {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message('Coped!!')
    }

    newBatchSF() {
        let shopItemOrders:Row[] = this.tableData.filter(r => !r.order)

        let data: {
            memberId: string,
            displayName: string,
            items: string[],
            phone: string,
            shippingCode: string,
            orderId: string,
            itemString: string
        }[] = this.tableData.filter(r => !!r.order)
            .map(row => {
                //  find member order
                const couponItems = shopItemOrders.filter(d => d.member_id === row.member_id)
                const foundCoupon = !!couponItems && couponItems.length > 0
                if (foundCoupon) {
                    shopItemOrders = shopItemOrders.filter(d => d.member_id !== row.member_id)
                }

                const order = row.order!
                return {
                    memberId: row.member_id,
                    displayName: this.displayName[row.member_id],
                    items: row.items,
                    phone: order.phone,
                    shippingCode: order.shipping_code.trim(),
                    orderId: order._id,
                    itemString: row.items.join(',') + addCoupon(couponItems)
                }
            })
            .sort((a, b) => (a.itemString > b.itemString) ? 1 : -1)

        function addCoupon(rows: Row[]) {
            return rows.length ? ',' + rows.map(r=>r.short).join(',') : ''
        }

        const rows: string[] = []
        for (const d of data) {
            let address = d.shippingCode;
            // let address = sfLookup[d.shippingCode] ? sfLookup[d.shippingCode].address : d.shippingCode
            // if (!address.startsWith('香港'))
            //     address = '香港' + address

            // 香港長沙灣紗廠工業大廈5期11樓C室
            const orderId = '\'' + d.orderId.toString()
            rows.push([orderId, '中国香港', 0, '公司名稱 : AfterSchool', 'AfterSchool', '91036749', '852D', '', '', '中国香港', 0, '', orderId, '', d.phone, address, '', '', '', '', '', '', '', d.itemString, d.itemString, 50, '港幣', 1, '本', '', d.itemString, '寄付月結', '順豐特快'].join('\t'))
        }

        copy(rows.join('\n'), {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message('Coped!!')
    }

    filterUpdated() {
        const addedMid = {}
        this.tableData = this.rawTableData.filter(row => {
            if (row.order) {
                for (const item of row.items) {
                    if (this.filteredCourse.includes(item)) {
                        addedMid[row.member_id] = true
                        return true
                    }
                }
                return false
            } else {
                return addedMid[row.member_id]
            }
        })
        this.updateSummary()
    }
}
