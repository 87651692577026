














































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {NoteLanguage, NoteType, Order, ReSend} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {}
})
export default class ResendDialog extends Vue {
    @Prop()
    order: Order

    @Prop({
        default: () => {
            return false
        }
    })
    visible!: boolean

    phone: string = ''
    sf: string = ''

    options: {
        courseId: string,
        available: boolean,
        languages: NoteLanguage[],
        selectedLang: NoteLanguage,
        send: boolean
    }[] = []

    noteLangText = {
        [NoteLanguage.NONE]: '-',
        [NoteLanguage.CHINESE]: '中文',
        [NoteLanguage.ENGLISH]: '英文',
        [NoteLanguage.BOTH]: '中英對照'
    }

    @Watch('visible')
    async vChanged(v) {
        if(!v)
            return

        this.phone = this.order.phone
        this.sf = this.order.shipping_code

        const res = await createRequest('/resend-options', 'post', {},
            {courses: this.order.courses.map(oc => oc.course_id)}).send()

        this.options = this.order.courses.map(oc => {
            const op = res.data.options[oc.course_id]
            const type: NoteType = op.note_type
            const lang: NoteLanguage = op.language
            const available = [NoteType.PHYSICAL, NoteType.PHYSICAL_NOT_READY].includes(type)

            return {
                courseId: oc.course_id,
                available,
                languages: lang === NoteLanguage.EITHER ? [NoteLanguage.CHINESE, NoteLanguage.ENGLISH] : [lang],
                selectedLang: lang === NoteLanguage.EITHER ? oc.language : lang,
                send: available
            }
        })
    }

    phoneInput(v: string) {
        this.phone = v.digits()
    }

    get canSend() {
        return this.phone.length === 8 && this.sf
    }
    async submit() {
        const courses: ReSend[] = this.options.filter(op=>op.send).map(op => {
            return {
                course_id: op.courseId,
                language: op.selectedLang
            }
        })

        await createRequest('/order/' + this.order._id + '/resend', 'post', {}, {
            phone: this.phone,
            shipping_code: this.sf,
            courses
        }).send()

        this.dialogVisible = false
        this.$message.success('Updated')
        this.$emit('submitted')
    }

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    get dialogVisible() {
        return this.visible
    }
}
