import {MailingStatus, OrderStatus} from '~/components/data-class/data-class'

export const statusOptions = [
    {
        text: 'Mailed',
        value: OrderStatus.MAILED
    }, {
        text: 'Paid',
        value: OrderStatus.PAID
    }, {
        text: 'Pending',
        value: OrderStatus.PENDING
    }, {
        text: 'Cancelled',
        value: OrderStatus.CANCELED
    }, {
        text: 'TB verified',
        value: OrderStatus.TO_BE_VERIFIED
    }, {
        text: 'V. failed',
        value: OrderStatus.VERIFICATION_FAILED
    }
]

export const mStatusOptions = [
    {
        text: 'Mailed',
        value: MailingStatus.MAILED
    },
    {
        text: 'TB Mailed',
        value: MailingStatus.TB_MAILED
    },
    {
        text: 'Pending',
        value: MailingStatus.PENDING
    },
    {
        text: '----',
        value: MailingStatus.NO_NEED
    },
]

export default statusOptions
