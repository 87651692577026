import {PaymentMethod} from '~/components/data-class/data-class'

const paymentOptions = [
    {
        text: 'N/A',
        value: PaymentMethod.NOT_SELECTED
    }, {
        text: 'WeChat Pay HK',
        value: PaymentMethod.WECHAT
    }, {
        text: 'AliPay HK',
        value: PaymentMethod.ALIPAY
    }, {
        text: 'Bank Transition',
        value: PaymentMethod.BANK_TRANSACTION
    }, {
        text: 'Credit Card(Online)',
        value: PaymentMethod.ONLINE_CARD
    }, {
        text: 'AliPay(Online)',
        value: PaymentMethod.ONLINE_ALIPAY
    }, {
        text: 'WeChat Pay(Online)',
        value: PaymentMethod.ONLINE_WECHAT
    }, {
        text: 'Cash',
        value: PaymentMethod.CASH
    }
]

export default paymentOptions
